import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
    instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    },
    // enableAutoRouteTracking: true, // Don't need this if using react plugin
    autoTrackPageVisitTime: true,
    endpointUrl: process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_INGESTION_ENDPOINT
  }
});
if (process.env.REACT_APP_APPLICATION_INSIGHTS_ENABLED == "true"){
    ai.loadAppInsights();
}


export { reactPlugin, ai };